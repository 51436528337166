import { makeToast } from "./makeToast";
import type { ToastContent, ToastOptions } from "./types";

/**
 * An opinionated toast for displaying an info condition with a default auto-close of 10 seconds as defined in `BrazeToaster`
 * @param content string or JSX to be displayed in the toast
 * @example
 * infoToast(t("download-zip-file-info", "Downloading HTML now for all variants..."));
 */
export const infoToast = (content: ToastContent, options?: ToastOptions) => {
  return makeToast("info", content, options);
};
