/**
 * @fileoverview copy/pasted from https://github.com/i18next/i18next-icu/blob/master/src/utils.js
 */
function getLastOfPath(object, path, Empty?: any) {
  function cleanKey(key) {
    return key && key.indexOf("###") > -1 ? key.replace(/###/g, ".") : key;
  }

  function canNotTraverseDeeper() {
    return !object || typeof object === "string";
  }

  const stack = typeof path !== "string" ? [].concat(path) : path.split(".");
  while (stack.length > 1) {
    if (canNotTraverseDeeper()) {
      return {};
    }

    const key = cleanKey(stack.shift());
    if (!object[key] && Empty) {
      object[key] = new Empty();
    }
    object = object[key];
  }

  if (canNotTraverseDeeper()) {
    return {};
  }
  return {
    obj: object,
    k: cleanKey(stack.shift()),
  };
}

export function setPath(object, path, newValue) {
  const { obj, k } = getLastOfPath(object, path, Object);

  obj[k] = newValue;
}

export function pushPath(object, path, newValue, concat) {
  const { obj, k } = getLastOfPath(object, path, Object);

  obj[k] = obj[k] || [];
  if (concat) {
    obj[k] = obj[k].concat(newValue);
  }
  if (!concat) {
    obj[k].push(newValue);
  }
}

export function getPath(object, path) {
  const { obj, k } = getLastOfPath(object, path);

  if (!obj) {
    return undefined;
  }
  return obj[k];
}

const arr = [];
const each = arr.forEach;
const slice = arr.slice;

export function defaults(obj) {
  // eslint-disable-next-line prefer-rest-params
  each.call(slice.call(arguments, 1), function (source) {
    if (source) {
      for (const prop in source) {
        if (obj[prop] === undefined) {
          obj[prop] = source[prop];
        }
      }
    }
  });
  return obj;
}

export function extend(obj) {
  // eslint-disable-next-line prefer-rest-params
  each.call(slice.call(arguments, 1), function (source) {
    if (source) {
      for (const prop in source) {
        obj[prop] = source[prop];
      }
    }
  });
  return obj;
}
