import React from "react";

import StackTracey from "stacktracey";

import { dangerToast } from "@lib/brazeToaster";
// eslint-disable-next-line local-eslint-rules/no-direct-dashboard-config-access
import config from "@lib/dashboardConfig";

import type { FeatureName as Features } from "../../../../../types/features";

export type FeatureName = Features;

/**
 * Get information used to display debugging details in the dangerToast
 */
const extractSourceLocation = () => {
  const trace = new StackTracey();

  const rawInfo = trace.items[2];

  const info = {
    code: rawInfo.callee,
    path: rawInfo.fileRelative.replace("./", ""),
    line: rawInfo.line,
  };
  return info;
};

/**
 * @deprecated use the useFeatureOn() from @services/useFeatureOn instead
 */
export const featureOn = (featureName: FeatureName) => {
  if (!config || !config.features) {
    return false;
  }
  if (!config.features.hasOwnProperty(featureName)) {
    if (process.env.NODE_ENV === "development") {
      const info = extractSourceLocation();
      console.error(new Error(`Feature ${featureName} does not exist`));
      dangerToast(
        <div>
          Feature does not exist:
          <ul>
            <li>
              <pre>{featureName}</pre>
            </li>
            <li>
              Code in <code>{info.path}</code>:
            </li>
            <li>
              Open the dev tools to see the entry corresponding to <code>{info.code}</code>
            </li>
          </ul>
        </div>
      );
    }
  }
  return !!config.features[featureName];
};
