import { makeToast } from "./makeToast";
import type { ToastContent, ToastOptions } from "./types";

/**
 * An opinionated toast for displaying an warning condition with a default auto-close of 10 seconds as defined in `BrazeToaster`
 * @param content string or JSX to be displayed in the toast
 * @example
 * warningToast(t("download-zip-file-info", "Downloading HTML now for all variants..."));
 */
export const warningToast = (content: ToastContent, options?: Omit<ToastOptions, "containerId">) => {
  return makeToast("warning", content, options);
};
