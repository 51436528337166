import { makeToast } from "./makeToast";
import type { ToastContent, ToastOptions } from "./types";

/**
 * An opinionated toast for displaying a success condition that auto-closes in 10 seconds by default as defined in `BrazeToaster`
 * @param content string or JSX to be displayed in the toast
 * @example
 * successToast(t("add-new-team-succeeded", "New team added successfully"));
 */
export const successToast = (content: ToastContent, options?: Omit<ToastOptions, "containerId">) => {
  return makeToast("success", content, options);
};
