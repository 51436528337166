/**
 * @owner @Appboy/frontend-ix
 * @fileoverview wrapper that adds types to the Dashboard configuration object
 * @note For the production app, see `dashboard/app/views/layouts/dashboard.html.erb` for
 *   where this gets interpolated into the main layout. In other environments, this is
 *   mocked out by webpack or other means
 */

export * from "./getFakeDashboardConfig";

export * from "./types";
export * from "./constants";

export default window.brazeDashboardConfig;
