/**
 * @owner @Appboy/frontend-ix
 */

import type { I18nConfig } from "@braze/i18n-scripts";
import { loadConfig } from "@braze/i18n-scripts";
import path from "path";
import { setupI18n } from "@braze/i18n-core";

import { BrazeICU } from "@lib/BrazeICU";

import translations from "./translations.braze.json";

declare const jest: any;

function getConfig() {
  const configArgument =
    // if window is not defined (command-line) or we're in a jest test, use the node version
    window === undefined || typeof jest === "object"
      ? path.resolve(__dirname, "..", "..", "..", ".i18nConfig.json")
      : // eslint-disable-next-line @typescript-eslint/no-var-requires
        (require("./.i18nConfig.json") as I18nConfig);
  return loadConfig(configArgument);
}
const config = getConfig();

// usage: https://confluence.braze.com/display/DASHINFRA/Internationalization+for+dashboard+developers
const i18n = setupI18n<typeof translations>(
  translations,
  config,
  () => {
    BrazeICU.setActiveTimezone();
  },
  BrazeICU
);

export { i18n };
