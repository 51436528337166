import React from "react";
import { I18nextProvider } from "react-i18next";

import { makeToast as beaconMakeToast } from "@braze/beacon-core";

import i18n from "../../i18n";
import { brazeToasterId } from "./constants";
import type { ToastContent, ToastIntent, ToastOptions } from "./types";

/**
 * Send a toast message
 *
 * See https://beacon-storybook.braze.com/?path=/docs/core-alerts-toaster--basic-use#maketoast for full documentation
 * @param intent one of `"danger"`, `"info"`, `"success"`, or `"warning"
 * @param content a string, or a React component
 * @param options makeToast options
 * @returns
 */
export const makeToast = (
  intent: ToastIntent,
  content: ToastContent,
  { containerId = brazeToasterId, ...options }: ToastOptions = {}
) => {
  // ensure that <Trans> works in toasts
  return beaconMakeToast(intent, <I18nextProvider i18n={i18n}>{content}</I18nextProvider>, {
    containerId,
    ...options,
  });
};
