import { makeToast } from "./makeToast";
import type { ToastContent, ToastOptions } from "./types";

/**
 * An opinionated toast for displaying an error condition that remains open until dismissed by the user
 * @param content string or JSX to be displayed in the toast
 * @example
 * dangerToast(t("developer-export-failure", "Failed to export developers. Please try again later."));
 */
export const dangerToast = (
  content: ToastContent,
  options: Omit<ToastOptions, "containerId" | "autoClose"> = {}
) => {
  return makeToast("danger", content, { ...options, autoClose: false });
};
