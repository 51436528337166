import sharedConstants from "@lib/shared_constants.json";

export const DASHBOARD_MAX_IMG_SIZE = 5 * 1024 * 1024; // 5MB in bytes
export const PUSH_ICON_IMAGE_EXPECTED_RATIO = sharedConstants.MEDIA_LIBRARY.ASPECT_RATIOS.ONE_TO_ONE;
export const ANDROID_NOTIFICATION_IMAGE_EXPECTED_RATIO = sharedConstants.MEDIA_LIBRARY.ASPECT_RATIOS.TWO_TO_ONE;
export const ANDROID_INLINE_IMAGE_EXPECTED_RATIO = sharedConstants.MEDIA_LIBRARY.ASPECT_RATIOS.THREE_TO_TWO;
export const ACCEPTED_IMAGE_TYPES = ["jpeg", "png"];
export const IOS_ACCEPTED_IMAGE_TYPES = ["jpeg", "png", "gif"];
export const ANDROID_SDK_FALLBACK_CHANNEL_ID = "com_appboy_default_notification_channel";

export type PushStoryTextAlignment = "start" | "center" | "end";
