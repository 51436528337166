import type { DeepPartial } from "@reduxjs/toolkit";

const isObject = (item: unknown) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

/**
 * Taken from https://thewebdev.info/2021/03/06/how-to-deep-merge-javascript-objects/
 *
 * This is used to merge an object with a partial of itself. This is more robust than
 * lodash's implementation, and far smaller
 *
 * WARNING: This overwrites the target object
 */
export const mergeDeep = <T>(target: T, ...sources: Array<DeepPartial<T>>): T => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {},
          });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key],
        });
      }
    }
  }

  return mergeDeep(target, ...sources);
};
